import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import BookOnlineOutlinedIcon from '@mui/icons-material/BookOnlineOutlined';
import DiscountOutlinedIcon from '@mui/icons-material/DiscountOutlined';

const icons = {
  HomeOutlinedIcon: HomeOutlinedIcon,
  PaymentOutlinedIcon: PaymentOutlinedIcon,
  ViewCarouselIcon: ViewCarouselIcon,
  BookOnlineOutlinedIcon: BookOnlineOutlinedIcon,
  DiscountOutlinedIcon: DiscountOutlinedIcon
};

export default {
  items: [
    {
      id: 'navigation',
      type: 'group',
      children: [
        {
          id: 'dashboard',
          title: 'Dashboard',
          type: 'item',
          icon: icons['HomeOutlinedIcon'],
          url: '/dashboard'
        },
        {
          id: 'payment-method',
          title: 'Metode Pembayaran',
          type: 'item',
          icon: icons['PaymentOutlinedIcon'],
          url: '/payment-method'
        },
        {
          id: 'alumni-banner',
          title: 'Alumni Banner',
          type: 'item',
          icon: icons['ViewCarouselIcon'],
          url: '/alumni-banner'
        },
        {
          id: 'promotion',
          title: 'Promo',
          type: 'item',
          icon: icons['BookOnlineOutlinedIcon'],
          url: '/promotion'
        },
        {
          id: 'voucher',
          title: 'Voucher',
          type: 'item',
          icon: icons['DiscountOutlinedIcon'],
          url: '/voucher'
        }
      ]
    }
  ]
};
