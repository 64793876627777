import { lazy } from 'react';
import { Navigate } from 'react-router';
import { getCache } from 'helpers/local-storage';
import Loadable from 'component/Loadable';
import MainLayout from 'layout/MainLayout';

const DashboardDefault = Loadable(lazy(() => import('views/Dashboard')));

const PaymentMethod = Loadable(lazy(() => import('views/PaymentMethod')));
const FormPaymentMethod = Loadable(lazy(() => import('views/PaymentMethod/form')));

const AlumniBanner = Loadable(lazy(() => import('views/AlumniBanner')));
const FormAlumniBanner = Loadable(lazy(() => import('views/AlumniBanner/form')));

const Promotion = Loadable(lazy(() => import('views/Promotion')));
const FormPromotion = Loadable(lazy(() => import('views/Promotion/form')));

const Voucher = Loadable(lazy(() => import('views/Voucher')));
const FormVoucher = Loadable(lazy(() => import('views/Voucher/form')));

// ==============================|| MAIN ROUTES ||============================== //

const accessToken = getCache('access_token');
const renderElement = () => {
  if (accessToken) {
    return <MainLayout />;
  } else {
    return <Navigate to="/login" />;
  }
};

const MainRoutes = {
  path: '/',
  element: renderElement(),
  children: [
    { path: 'dashboard', element: <DashboardDefault /> },
    {
      path: 'payment-method',
      children: [
        {
          index: true,
          element: <PaymentMethod />
        },
        {
          path: ':type/:id?',
          element: <FormPaymentMethod />
        }
      ]
    },
    {
      path: 'alumni-banner',
      children: [
        {
          index: true,
          element: <AlumniBanner />
        },
        {
          path: ':type/:id?',
          element: <FormAlumniBanner />
        }
      ]
    },
    {
      path: 'promotion',
      children: [
        {
          index: true,
          element: <Promotion />
        },
        {
          path: ':type/:id?',
          element: <FormPromotion />
        }
      ]
    },
    {
      path: 'voucher',
      children: [
        {
          index: true,
          element: <Voucher />
        },
        {
          path: ':type/:id?',
          element: <FormVoucher />
        }
      ]
    }
  ]
};

export default MainRoutes;
